<template>
  <el-container>
    <el-main style="overflow: auto !important">
      <pro-document-breadcrumbs
        :breadcrumbs="breadcrumbs"
        user-role="procurer"
      ></pro-document-breadcrumbs>
      <pro-report
        ref="proReport"
        title="menu.DocumentSearch"
        :columns="columns"
        v-model="documentList"
        :loading="loading"
        row-key="id"
        auto-search
        selectable
        show-columns-setting
        @row-select-event="rowClickEvent"
      >
      </pro-report>
    </el-main>
  </el-container>
</template>
<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProReport from "@/components/PROSmart/Report/ProReport";

export default {
  components: {
    ProDocumentBreadcrumbs,
    ProReport,
  },
  props: {
    backupBuyerMode: Boolean,
  },
  data() {
    return {
      columns: [
        {
          name: "ref",
          label: this.getRes("Document.Ref"),
          field: "ref",
          align: "left",
          sortable: true,
          style: "width: 200px;",
          searchable: true,
          type: "string",
        },
        {
          name: "subjEng",
          label: this.getRes("Document.Subject"),
          field: "subjEng",
          align: "left",
          style: "width: 400px;word-break: break-all;",
          searchable: true,
          type: "string",
        },
        {
          name: "commodity",
          label: this.getRes("Document.CommodityCode"),
          field: "sourcingCategories",
          align: "left",
          style: "width: 400px;",
          searchable: true,
          type: "commodity",
          defaultShow: false,
        },
        {
          name: "issueDate",
          label: this.getRes("CustMenu.Field.IssueDate"),
          field: "issueDate",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "datetimeNull",
          convertTo: "datetimeNull",
        },
        {
          name: "closingDate",
          label: this.getRes("Document.ClosingDate"),
          field: "closingDate",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "datetimeNull",
          convertTo: "datetimeNull",
        },
        {
          name: "buyer",
          label: this.getRes("CustMenu.Field.BuyerName"),
          field: "buyer",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "status",
          label: this.getRes("CustMenu.Field.TenderStatus"),
          field: "status",
          align: "left",
          searchable: true,
          type: "documentStatus",
          module: "tender",
        },
      ],
      breadcrumbs: [
        {
          name: "menu.Document",
          icon: "PROSmart-DocumentBox",
        },
        { name: "menu.DocumentSearch" },
      ],
      fullDocumentList: [],
      documentList: [],
      loading: true,
      filterCommodityCode: "",
      moduleInput: "",
      language: "",
    };
  },
  created() {
    this.moduleInput = (this.$route.params.module || "").toLowerCase();
    this.language = localStorage.getItem("Language");
  },
  async mounted() {
    this.loading = true;
    this.getModuleColumn();

    const documentListPromise =
      this.backupBuyerMode === true
        ? this.$proSmart.tender.getAllDocumentList(this)
        : this.$proSmart.tender.getDocumentList(this);

    Promise.all([
      documentListPromise,
      // this.$proSmart.procurerUi.getProcurerCommodity(this),
      this.$proSmart.codeTable.getCommodityCode(this),
    ])
      .then(
        /**
         * @param {Array<import('@pro-smart/hcm-custom-project-api-middleware/proSmart').proSmartDocumentList>} list
         * @param commodityCode
         */
        ([list, commodityCode]) => {
          let commodityCodeMapping = commodityCode.reduce(
            (obj, { code, description }) => ({ ...obj, [code]: description }),
            {}
          );

          list //TODO: user role
            .map((item) => {
              this.fullDocumentList.push(
                Object.assign(item, {
                  userRole: "ProSmartBuyer",
                  id: item.id,
                  buyer: this.getBuyerName(item.buyerName),
                  class: item.readOnly ? "" : "text-primary",
                  sourcingCategories: this.getSourcingCategories(
                    item.commodityCodeLevelIds,
                    commodityCodeMapping
                  ),
                })
              );
            });

          this.getDocumentModuleLists();
        }
      )
      .finally(() => (this.loading = false));
  },
  methods: {
    getSourcingCategories(commodityCodeLevelId, commodityCodeMapping) {
      let sourcingCategories = {
        code: !commodityCodeLevelId
          ? []
          : commodityCodeLevelId.slice(1, commodityCodeLevelId.length),
        value: [],
      };

      if (!commodityCodeLevelId || commodityCodeLevelId.length === 0)
        return sourcingCategories;

      for (let i = 1; i < commodityCodeLevelId.length; i++) {
        let key = commodityCodeLevelId.slice(1, i + 1).join("-");

        sourcingCategories.value.push(commodityCodeMapping[key]);
      }

      return sourcingCategories;
    },
    getDocumentModuleLists() {
      this.documentList = this.fullDocumentList.filter(
        (item) => item.moduleName.toLowerCase() === this.moduleInput
      );
    },
    rowClickEvent(row) {
      if (!row.readOnly) this.$router.push(this.getRoute(row));
    },
    getModuleColumn() {
      this.$refs.proReport.updateColumnsSetting(
        "status",
        "module",
        this.moduleInput
      );

      let refMapping = {
        eoi: this.getRes("Document.EoiRef"),
        tender: this.getRes("Document.TenderRef"),
        pq: this.getRes("Document.PqRef"),
        rfq: this.getRes("Document.RfqRef"),
        rfi: this.getRes("Document.RfiRef")
      };

      this.$refs.proReport.updateColumnsSetting(
        "ref",
        "label",
        refMapping[this.moduleInput]
      );
    },
    getBuyerName(buyers) {
      return buyers
        .map((buyer) => buyer[this.language] || buyer["English"])
        .join(", ");
    },
    getClass(item) {
      if (!item.readOnly) return "text-primary";
    },
    getRoute(todo) {
      let params = {
        id: todo.id,
        userRole: todo.userRole,
        category: "search",
        requestType: "document",
      };
      return {
        name: "DocumentDashboard",
        params,
      };
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.moduleInput = to.params.module.toLowerCase();

    this.getModuleColumn();
    this.getDocumentModuleLists();
    this.$refs.proReport.clearSearchFilter();
    next();
  },
};
</script>
